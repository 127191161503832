<template>
  <v-container fluid class="ml-0">
    <v-row
      ><v-col cols="6">
        <v-row class="mt-1 mx-0">
          <v-btn color="primary" class="ma-1" @click="SaveData">
            <v-icon left> mdi-cloud-upload-outline </v-icon>
            Upload
          </v-btn>
          <v-btn color="primary" class="my-1" @click="DiscardEdits">
            <v-icon left> mdi-close </v-icon>
            Discard
          </v-btn>
          <v-spacer />
        </v-row>

        <v-row class="mb-2 mx-1">
          Warning - this is a bulk upload. Only use with the correct format
        </v-row>
        <v-jsoneditor v-model="jsonInput" height="80vh" :options="jsonoptions" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import ProcessToolData from "@/support/processToolData.js";
import VJsoneditor from "v-jsoneditor/src/index";
import dataloader from "@/api/dataloader.js";

export default {
  components: {
    VJsoneditor,
  },

  data: () => ({
    jsonInput: {
      help: "please paste a set of instructions",
    },
    jsonoptions: { mode: "code" },
  }),
  computed: {
    authObject() {
      return {
        editEnable: this.$store.getters["menu/canEditTools"],
        permissions: this.$store.getters["user/permissions"],
      };
    },
    AllTools() {
      return this.$store.getters["tools/getTools"];
    },
  },
  methods: {
    DiscardEdits() {
      this.jsonInput = { help: "please paste a set of instructions" };
    },
    SaveData() {
      console.log("Creating tool update");
      var commandList = [];
      for (const [toolname, instruction] of Object.entries(this.jsonInput)) {
        var entry = this.AllTools[toolname];
        if (entry !== undefined) {
          commandList.push({ _id: entry._id, ...instruction });
        }
      }
      console.log(JSON.stringify(commandList));
      dataloader.sendToolUpdateData(commandList);
      //dataloader.sendToolReplacement(this.jsonInput);
      //this.$store.commit("tools/updateSingleTool", this.jsonInput);
    },
  },
};
</script>

<style>
div.jsoneditor-menu {
  background-color: #455a64;
  border: thin solid #263238;
}
div.jsoneditor {
  border: thin solid #263238;
}
a.jsoneditor-poweredBy {
  color: #eceff1;
}
</style>
